<template>
  <div class="lfont main-content">
    <h1><i class="far fa-cogs"></i> ຕັ້ງຄ່າ</h1>
    <hr />
    <v-btn depressed color="primary" @click="auth_google">
      Google Auth
    </v-btn>
    <hr>
    <table class="table">
      <tr>
        <td>{{ $t("setting.title") }}</td>
        <td>{{ $t("setting.setting") }}</td>
        <td>{{ $t("setting.description") }}</td>
        <td>{{ $t("setting.example") }}</td>
      </tr>
      <tbody>
        <tr>
          <td>{{ $t("setting.approvall.title") }}</td>
          <td>
            <input type="text" class="input-text" v-model="approvalCode" @change="addSetting('approval_code')" />
          </td>
          <td>{{ $t("setting.approvall.description") }}</td>
          <td>
            <span class="text-primary">
              <span v-if="approvalCode != ''">{{ approvalCode }}</span>
              <span v-else>ABC</span> </span>0000000
          </td>
        </tr>
        <tr>
          <td>{{ $t("setting.hr_approve_ot.title") }}</td>
          <td>
            <v-select class="center align-center" @keydown.enter="focusNext" :label="$t('Dashboard.menu.approve_label')"
              dense outlined v-model="approve" required :items="approval_value" item-text="name"
              @change="addSetting('hr_approve_ot')">
            </v-select>
          </td>
          <td>
            {{ $t("setting.hr_approve_ot.description") }}
          </td>
          <td></td>
        </tr>
        <tr>
          <td>{{ $t("setting.holiday_bring_up.holiday_bring_up_expire") }}</td>
          <td>
              <DatePicker class="date-time" style="width: 100%" type="date" :placeholder="$t('setting.holiday_bring_up.date_placeHolder')" v-model="holiday_bring_up_expire"
                valueType="format" slot="activator" :append-to-body="true" @change="addSetting('holiday_bring_up_expire')">
              </DatePicker>
          </td>
          <td>{{ $t("setting.holiday_bring_up.holiday_bring_up_expire_description") }}</td>
          <td>{{ $t("setting.holiday_bring_up.example_value") }}</td>
        </tr>
        <!-- <tr>
          <td>ສະແກນ ເຂົ້າ - ອອກ</td>
          <td>
            <v-row class="pt-3 pb-3">
              <v-col
                cols="12"
                md="9"
                lg="9"
                sm="9"
                xs="9"
                v-if="setting_value_object.wifi != ''"
              >
                <v-text-field
                  outlined
                  dense
                  v-model="
                    setting_value_object.wifi == 'no'
                      ? ''
                      : setting_value_object.wifi
                  "
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
                lg="3"
                sm="3"
                xs="3"
                v-if="setting_value_object.wifi != ''"
              >
                <v-btn color="error" class="btn btn-primary">
                  Reset
                </v-btn>
              </v-col>

              <v-col cols="12" md="3" lg="3">
                <v-checkbox
                  class="label-input"
                  label="GPS"
                  v-model="setting_value_object.gps"
                  @change="addScan('check_in_out')"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="12" md="3" lg="3">
                <v-checkbox
                  class="label-input"
                  label="WIFI"
                  v-model="setting_value_object.wifi"
                  @change="addScan('check_in_out')"
                >
                </v-checkbox>
              </v-col>
            </v-row>
          </td>
          <td>
            ຖ້າເລືອກ GPS ເວລາພະນັກງານສະແກນເຂົ້າຈະອີງຕາມຈຸດ GPS
            ທີ່ບໍລິສັດຕັ້ງໄວ້<br />
            ຖ້າເລືອກ WIFI ເວລາພະນັກງານສະແກນເຂົ້າຈະອີງຕາມຈຸດສັນຍານ WIFI
            ຂອງບໍລິສັດ<br />
            ແລະ ສາມມາດເລືອກທັງສອງໄດ້
          </td>
          <td></td>
        </tr> -->

        <!-- <tr>
          <td>ລະຫັດຂໍລາພັກ</td>
          <td>
            <input type="text" class="input-text" />
          </td>
          <td>ລະຫັດທີ່ໃຊ້ເວລາຂໍລາພັກ</td>
          <td><span class="text-primary">ABC</span>079534</td>
        </tr> -->
        <tr>
          <td>{{ $t("setting.send_mail.title") }}</td>
          <td>
            <v-checkbox v-model="setting_mail_object.approval" :label="$t('setting.send_mail.setting.approvall')"
              @change="addSetting('send_to_mail')"></v-checkbox>
            <v-checkbox v-model="setting_mail_object.meeting" :label="$t('setting.send_mail.setting.meeting')"
              @change="addSetting('send_to_mail')"></v-checkbox>
            <v-checkbox v-model="setting_mail_object.leave" :label="$t('setting.send_mail.setting.leave')"
              @change="addSetting('send_to_mail')"></v-checkbox>
            <v-checkbox v-model="setting_mail_object.allocation" :label="$t('setting.send_mail.setting.todolist')"
              @change="addSetting('send_to_mail')"></v-checkbox>
          </td>
          <td>
            {{ $t("setting.send_mail.description") }} <i class="far fa-check"></i>
          </td>
          <td></td>
        </tr>
        <tr>
          <td>{{ $t("setting.period_noti_emp_contract.title") }}</td>
          <td>
            <input type="text" class="input-text" v-model="periodNotiEmpContract"
              @change="addSetting('period_noti_emp_contract')" /> {{ $t("setting.period_noti_emp_contract.setting") }}
          </td>
          <td>{{ $t("setting.period_noti_emp_contract.description") }}</td>
          <td>{{ $t("setting.period_noti_emp_contract.example") }}</td>
        </tr>
      </tbody>
    </table>
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {

  data() {
    return {
      periodNotiEmpContract: 10,
      isLoading: false,
      settingData: [],
      holiday_bring_up_expire: "",
      approvalCode: "",
      approval_value: ["yes", "no"],
      approve: "",
      setting_mail_object: {
        approval: false,
        leave: false,
        meeting: false,
        allocation: false,
      },
    };
  },
  components: {
    Loading,
  },

  methods: {
    auth_google() {
      this.$axios.get('/google/auth')
        .then(response => {
          console.log(response.data.authUrl);
          window.location = response.data.authUrl
        })
        .catch(error => {
          console.log(error)
        })
    },

    addSetting(setting_name) {
      let setting_value = '';
      let setting_value_object = '';
      if (setting_name == 'hr_approve_ot') {
        setting_value = this.approve;
      } else if (setting_name == 'approval_code') {
        setting_value = this.approvalCode;
      } else if (setting_name == 'send_to_mail') {
        setting_value_object = this.setting_mail_object;
      } else if (setting_name == 'period_noti_emp_contract') {
        setting_value = this.periodNotiEmpContract;
      } else if(setting_name == 'holiday_bring_up_expire'){
        setting_value = this.holiday_bring_up_expire
      }
      
      this.isLoading = true;
      this.$axios
        .post(`company/setting`, {
          setting_name: setting_name,
          setting_value: setting_value,
          setting_value_object: setting_value_object
        })
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
          }
          
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
          if (error.response.status === 500) {
            this.$notification.ShowErrors(
              "top-right",
              "danger",
              3000,
              "server error"
            );
          }
        });
    },

    fetchCompanySetting() {
      this.isLoading = true;
      this.$axios
        .get(`company/setting`)
        .then((res) => {
          if (res.status === 200) {
            this.settingData = res.data.data;
            this.settingData.forEach((item) => {
              if (item.setting_name == "approval_code") {
                this.approvalCode = item.setting_value;
              }
              if (item.setting_name == "hr_approve_ot") {
                this.approve = item.setting_value;
              }
              if (item.setting_name == "send_to_mail") {
                this.setting_mail_object = item.setting_value_object;
              }
              if (item.setting_name == "period_noti_emp_contract") {
                this.periodNotiEmpContract = item.setting_value;
              }
              if(item.setting_name='holiday_bring_up_expire')
              {
                this.holiday_bring_up_expire = item.setting_value
              }
            });
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchCompanySetting();
  },
};
</script>

<style scope lang="scss">
.main-content {
  margin: 20px;
  padding: 10px;
  border: 1px solid rgb(239, 239, 239);
  border-radius: 5px;
  min-height: 80vh;

  .input-text {
    border: 1px solid rgb(225, 224, 224);
    padding: 5px;
  }

  .table tr th,
  .table tr td {
    border: 1px solid rgb(225, 224, 224);
  }

  .table tbody {
    border: 1px solid rgb(225, 224, 224);
  }
}
</style>
