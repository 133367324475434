<template>
  <v-container fluid>
    <div class="from-content">
      <v-form class="from" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <div class="image-emp-profile">
              <div class="image-container">
                <div class="image-company">
                  <img :src="showImage || companyProfile.url_Logo" alt="" />
                  <div class="image-button">
                    <div class="btn-img">
                      <i class="fas fa-camera"></i>
                      <input
                        @change="PreviewImage"
                        class="file-input"
                        type="file"
                        name="resume"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field
              outlined
              dense
              label="Name"
              v-model="companyProfile.name"
              @keydown.enter="focusNext"
            >
            </v-text-field>
          </v-col>

          <v-col cols="6" md="6">
            <v-text-field
              outlined
              dense
              label="Email"
              v-model="companyProfile.email"
              @keydown.enter="focusNext"
            >
            </v-text-field>
          </v-col>

          <v-col cols="6" md="6">
            <v-text-field
              outlined
              dense
              label="Phone"
              v-model="companyProfile.phone"
              @keydown.enter="focusNext"
            >
            </v-text-field>
          </v-col>

          <v-col cols="6" md="6">
            <v-text-field
              dense
              outlined
              label="Address"
              v-model="companyProfile.address"
              @keydown.enter="focusNext"
            >
            </v-text-field>
          </v-col>

          <v-col cols="6" md="6">
            <v-select
              outlined
              dense
              label="BusinessType"
              v-model="companyProfile.type_business_id"
              :items="listBusinessType"
              item-text="name"
              item-value="id"
              @keydown.enter="focusNext"
            >
            </v-select>
          </v-col>

          <v-col cols="6" md="6">
            <DatePicker
              class="date-time"
              style="width: 100%"
              type="date"
              v-model="companyProfile.founding_date"
              valueType="format"
              slot="activator"
              :append-to-body="true"
              name="date_of_birth"
              placeholder="Founding Date"
              @keydown.enter="ValidateForm"
            ></DatePicker>
          </v-col>

          <v-col cols="12" md="12" lg="12">
            <label class="label-input">{{ $t("Company.tax") }}</label>
            <v-radio-group row v-model="companyProfile.include_salary_tax">
              <v-radio
                class="label-input"
                value="yes"
                :label="$t('Company.tax_yes')"
              >
              </v-radio>
              <v-radio
                class="label-input"
                value="no"
                :label="$t('Company.tax_no')"
              >
              </v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" md="12" lg="12">
            <label class="label-input">{{ $t("Company.sso") }}</label>
            <v-radio-group row v-model="companyProfile.sso_charge">
              <v-radio
                class="label-input"
                value="employee"
                :label="$t('Company.sso_emp')"
              >
              </v-radio>
              <v-radio
                class="label-input"
                value="company"
                :label="$t('Company.sso_company')"
              >
              </v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="6" md="6">
            <v-btn
              class="btn-save-change"
              @click="ValidateForm"
              :loading="btnLoading"
            >
              Updated
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { ShowErrors } from "@/plugins/Notification";

export default {
  components: {
    DatePicker,
  },
  props: {
    companyProfile: {},
  },
  data() {
    return {
      btnLoading: false,
      showImage: "",
      logo: "",
      server_errors: {
        name: "",
        email: "",
        phone: "",
        address: "",
      },
      listBusinessType: [],
    };
  },
  methods: {
    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll("input"));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },

    PreviewImage: function (event) {
      const input = event.target;
      const file = input.files[0];
      const fileType = file["type"];
      const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!validImageTypes.includes(fileType)) {
        this.$notification.ShowErrors(
          "top-right",
          "danger",
          3000,
          "image must be jpeg,png"
        );
      } else if (input.files && input.files[0]) {
        this.imageFile = input.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.showImage = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.UploadImage(this.imageFile);
      }
    },

    UploadImage(imageFile) {
      let formData = new FormData();
      formData.append("imageFile", imageFile);
      this.$axios.post(`upload-file`, formData).then((res) => {
        if (res.status === 200) {
          this.logo = res.data.filename;
        }
      });
    },
    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.updated();
        this.btnLoading = true;
      }
    },

    getBusinessType() {
      this.$axios
        .get(`company/type-business`)
        .then((res) => {
          this.listBusinessType = res.data.data;
        })
        .catch(() => {});
    },

    updated() {
      const formData = new FormData();
      formData.append("name", this.companyProfile.name);
      formData.append("email", this.companyProfile.email);
      formData.append("phone", this.companyProfile.phone);
      formData.append("address", this.companyProfile.address);
      formData.append("type_business_id", this.companyProfile.type_business_id);
      formData.append("founding_date", this.companyProfile.founding_date);
      formData.append(
        "include_salary_tax",
        this.companyProfile.include_salary_tax
      );
      formData.append("sso_charge", this.companyProfile.sso_charge);
      if (this.imageFile) {
        formData.append("newLogo", this.logo);
      }
      this.$axios
        .post(`company/update-profile`, formData)
        .then((res) => {
          if (res.data.code === 200) {
            setTimeout(() => {
              this.$emit("close");
              this.$emit("success");
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
          }
          this.btnLoading = false;
        })
        .catch((error) => {
          if (error.response.status === 422) {
            const obj = error.response.data.errors;
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
              this.$notification.ShowErrors(
                "top-right",
                "danger",
                3000,
                (this.server_errors[key] = value[0])
              );
            }
          }
          this.btnLoading = false;
        });
    },
  },
  created() {
    this.getBusinessType();
    console.log(this.companyProfile);
  },
};
</script>

<style scoped lang="scss">
.image-emp-profile {
  width: 100%;
  height: 180px;
}

.from-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 50px 200px;

  .from {
    width: 700px;
    height: 500px;
    padding: 0 20px;
  }
}
</style>
